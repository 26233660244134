import LoaderUtilsService2 from "../services/admin/utils/loaderUtilsService2";

const CustomLoader = async () => {
    const region = localStorage.getItem('region')
    try {
        const url = `custom/${region}`;
        const custom_data = await LoaderUtilsService2(url);
        // console.log(custom_data)

        localStorage.setItem("customizations", JSON.stringify(custom_data));

        const custom_directory = {
            main_color: custom_data[0]['custom'][0]["color_main"] || "black",
        };

        document.documentElement.style.setProperty('--color-main', custom_directory["main_color"]);
    } catch (error) {
        console.error(`Error al cargar las costomizaciones de ${region}`);
    }
}


export default CustomLoader
import React, { useState, useEffect } from "react";
import '../../../styles/contens.css'
import NumberFormatter from "../../../components/Formarts";
import BarChart from "../../../components/charts/BarChart"
import DownloaderModal from '../../downloader/FinancialPDF'

const TableTotals = ({ data, yearsUse, anualUse }) => {

  const totalTour = yearsUse * anualUse;
  let financialTable = null;
  let financialTotal = null;
  let fuelFinancialTable = null;
  let fuelFinancialTotal = null;

  let consuptionTable = null;
  let consuptionTotal = null;
  let fuelConsuptionTable = null;
  let fuelConsuptionTotal = null;

  let maintenanceTable = null;
  let maintenanceTotal = null;
  let fuelMaintenanceTable = null;
  let fuelMaintenanceTotal = null;

  let consumablesTable = null;
  let consumablesTotal = null;
  let fuelConsumablesTable = null;
  let fuelConsumablesTotal = null;

  let bateryTable = null;
  let bateryTotal = null;

  let assuranceTable = null;
  let assuranceTotal = null;
  let fuelAssuranceTable = null;
  let fuelAssuranceTotal = null;

  let productivityTable = null;
  let productivityTotal = null;
  let fuelProductivityTable = null;
  let fuelProductivityTotal = null;

  try {
    financialTable = data[0]["financialTable"];
    financialTotal = data[0]["financialTotal"];
    fuelFinancialTable = data[0]["fuelFinancialTable"];
    fuelFinancialTotal = data[0]["fuelFinancialTotal"];

    consuptionTable = data[0]["consuptionTable"];
    consuptionTotal = data[0]["consuptionTotal"];
    fuelConsuptionTable = data[0]["fuelConsuptionTable"];
    fuelConsuptionTotal = data[0]["fuelConsuptionTotal"];

    maintenanceTable = data[0]["maintenanceTable"];
    maintenanceTotal = data[0]["maintenanceTotal"];
    fuelMaintenanceTable = data[0]["fuelMaintenanceTable"];
    fuelMaintenanceTotal = data[0]["fuelMaintenanceTotal"];

    consumablesTable = data[0]["consumablesTable"];
    consumablesTotal = data[0]["consumablesTotal"];
    fuelConsumablesTable = data[0]["fuelConsumablesTable"];
    fuelConsumablesTotal = data[0]["fuelConsumablesTotal"];

    bateryTable = data[0]["bateryTable"];
    bateryTotal = data[0]["bateryTotal"];

    assuranceTable = data[0]["assuranceTable"];
    assuranceTotal = data[0]["assuranceTotal"];
    fuelAssuranceTable = data[0]["fuelAssuranceTable"];
    fuelAssuranceTotal = data[0]["fuelAssuranceTotal"];

    productivityTable = data[0]["productivityTable"];
    productivityTotal = data[0]["productivityTotal"];
    fuelProductivityTable = data[0]["fuelProductivityTable"];
    fuelProductivityTotal = data[0]["fuelProductivityTotal"];
  } catch (error) {
    console.log(error);
  }


  const [showAll, setShowAll] = useState(false);
  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };

  
  const [showTables, setShowTables] = useState(false);
  const toggleTables = () => {
    setShowTables(!showTables);
  };

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  };


  useEffect(() => {
    const elementsWithClass = document.querySelectorAll('.highcharts-number');
    elementsWithClass.forEach(element => {
      let number = parseFloat(element.textContent);
      let formattedNumber = number.toLocaleString('es-ES', options);
      element.textContent = formattedNumber;
    });
  }, [BarChart]);


  if (!data || data === null) {
    return (
      <p className="subtitle">Sin resultados finales</p>
    )
  }

  return (
    <div className="">
      <div id="downloader-sec">
        <div className="father-box">
          <div className="child-box shadow-left">
          <h4 className='subtitle'>Costo por kilómetro del vehículo eléctrico</h4>
            <p className='big-result-text'>
              $ <NumberFormatter number={(
                financialTotal +
                consuptionTotal +
                maintenanceTotal +
                consumablesTotal +
                bateryTotal +
                assuranceTotal +
                productivityTotal
              ) / parseFloat(totalTour)} />
            </p>
            <hr />
            <h4 className='subtitle'>Costo de propiedad del vehículo eléctrico</h4>
            <p className='big-result-text'>
              $ <NumberFormatter number={(financialTotal +
                consuptionTotal +
                maintenanceTotal +
                consumablesTotal +
                assuranceTotal +
                productivityTotal +
                parseFloat(bateryTable))} />
            </p>
            <br />
          </div>
          <div className="child-box shadow-left">
            <h4 className='subtitle'>Costo por kilómetro del vehículo a combustión</h4>
            <p className='big-result-text'>
              $ <NumberFormatter number={(
                parseFloat(fuelFinancialTotal) +
                parseFloat(fuelConsuptionTotal) +
                parseFloat(fuelMaintenanceTotal) +
                parseFloat(fuelConsumablesTotal) +
                parseFloat(fuelAssuranceTotal) +
                parseFloat(fuelProductivityTotal) +
                parseFloat(bateryTable)
              ) / parseFloat(totalTour)
              } />
            </p>
           <hr />
            <h4 className='subtitle'>Costo de propiedad del vehículo a combustión</h4>
            <p className='big-result-text'>
              $ <NumberFormatter number={(fuelFinancialTotal +
                fuelConsuptionTotal +
                fuelMaintenanceTotal +
                fuelConsumablesTotal +
                fuelAssuranceTotal +
                fuelProductivityTotal +
                parseFloat(bateryTable))} />
            </p>
            <br />
          </div>
        </div>
        <br />
        <br />
        <div className="father-box">
          <div className="child-box shadow-left">
            <BarChart
              data={{
                categories: ["Costo de propiedad", "Productividad", "Aseguramiento", "Consumibles", "Mantenimientos", "Consumos", "Compra"],
                series: [
                  {
                    name: "Eléctrico",
                    data: [
                      (fuelFinancialTotal +
                        consuptionTotal +
                        maintenanceTotal +
                        consumablesTotal +
                        assuranceTotal +
                        productivityTotal +
                        parseFloat(bateryTable)),
                      productivityTotal, assuranceTotal, consumablesTotal, maintenanceTotal, consuptionTotal, financialTotal],
                  },
                  {
                    name: 'Combustión',
                    data: [
                      (fuelFinancialTotal +
                        fuelConsuptionTotal +
                        fuelMaintenanceTotal +
                        fuelConsumablesTotal +
                        fuelAssuranceTotal +
                        fuelProductivityTotal +
                        parseFloat(bateryTable)),
                      fuelProductivityTotal, fuelAssuranceTotal, fuelConsumablesTotal, fuelMaintenanceTotal, fuelConsuptionTotal, fuelFinancialTotal],
                  }
                ],
              }}
            />
          </div>
        </div>
      </div>
      <br /><br />
      <DownloaderModal />
      <br /><br />
      <button onClick={toggleTables} className="shadow-left">
        {showTables ? 'Ocultar tablas' : 'Mostrar tablas'}
      </button>
      <br /><br /><br />
      {showTables && (
        <div>
          <div className="title">
            <h4 className='subtitle'>Compra</h4>
            {!showAll ? (
        <button onClick={handleShowMore} className="shadow-left">Ver tablas</button>
      ) : (
        <button onClick={handleShowLess}>Cerrar tablas</button>
      )}
          </div>
          <hr />
          <div className="father-box">
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                {showAll ? (
                  <>          
                  <thead>
                  <tr>
                    <th className="table-head">Mes</th>
                    <th className="table-head">Eléctrico</th>
                  </tr>
                </thead>      
                  <tbody>                
                  {financialTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    financialTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                </tbody></>
                ) : (
                  <></>
                )}
                <tr>
                    <td className="result-text">
                      <p>Total: $ {financialTotal !== null && financialTotal !== undefined ? financialTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
              </table>
            </div>
            <div className="child-box shadow-left">
              <table class="table table-bordered">

                {showAll ? (
                  <>       
                  <thead>
                  <tr>
                    <th className="table-head">Mes</th>
                    <th className="table-head">Combustión</th>
                  </tr>
                </thead>         
                  <tbody>
                  {fuelFinancialTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    fuelFinancialTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                </tbody></>
                ) : (
                  <></>
                )}
                                  <tr>
                    <td className="result-text">
                      <p>Total: $ {fuelFinancialTotal !== null && fuelFinancialTotal !== undefined ? fuelFinancialTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
              </table>
            </div>
          </div>
          <br /><br /><br />


          <div className="title">
            <h4 className='subtitle'>Consumo</h4>
          </div>
          <hr />
          <div className="father-box">

            <div className="child-box shadow-left">

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Consumo de energía</th>
                  </tr>
                </thead>
                <tbody>
                  {consuptionTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    consuptionTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {consuptionTotal !== null && consuptionTotal !== undefined ? consuptionTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Consumo de combustible</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelConsuptionTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    fuelConsuptionTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {fuelConsuptionTotal !== null && fuelConsuptionTotal !== undefined ? fuelConsuptionTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br /><br /><br />
          <div className="title">
            <h4 className='subtitle'>Mantenimiento</h4>
          </div>
          <hr />
          <div className="father-box">
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Mantenimiento</th>
                  </tr>
                </thead>
                <tbody>
                  {maintenanceTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    maintenanceTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {maintenanceTotal !== null && maintenanceTotal !== undefined ? maintenanceTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Mantenimiento en combustión</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelMaintenanceTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    fuelMaintenanceTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {fuelMaintenanceTotal !== null && fuelMaintenanceTotal !== undefined ? fuelMaintenanceTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br /><br /><br />

          <div className="title">
            <h4 className='subtitle'>Consumibles</h4>
          </div>
          <hr />
          <div className="father-box">
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Consumibles</th>
                  </tr>
                </thead>
                <tbody>
                  {consumablesTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    consumablesTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {consumablesTotal !== null && consumablesTotal !== undefined ? consumablesTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Consumibles en combustión</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelConsumablesTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    fuelConsumablesTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {fuelConsumablesTotal !== null && fuelConsumablesTotal !== undefined ? fuelConsumablesTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br /><br /><br />

          <div className="title">
            <h4 className='subtitle'>Batería de tracción y motor de combustión</h4>
          </div>
          <hr />
          <div className="father-box">
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Cambio de batería</th>
                    <th className="table-head">Reparaciones motor de combustión</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      $ {" "}
                      {bateryTotal === null
                        ? "No data available"
                        : bateryTotal.toLocaleString()}
                    </td>
                    <td>$ {" "}
                      {bateryTable === null
                        ? "No data available"
                        : bateryTable.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br /><br /><br />
          <div className="title">
            <h4 className='subtitle'>Aseguramiento e impuestos</h4>
          </div>

          <hr />
          <div className="father-box">
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Aseguramiento</th>
                  </tr>
                </thead>
                <tbody>
                  {assuranceTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    assuranceTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {assuranceTotal !== null && assuranceTotal !== undefined ? assuranceTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Aseguramiento en combustión</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelAssuranceTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    fuelAssuranceTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {fuelAssuranceTotal !== null && fuelAssuranceTotal !== undefined ? fuelAssuranceTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br /><br /><br />
          <div className="title">
            <h4 className='subtitle'>Productividad</h4>
          </div>
          <hr />
          <div className="father-box">
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <th className="table-head">Productividad</th>
                  </tr>
                </thead>
                <tbody>
                  {productivityTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    productivityTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}
                  <tr>
                    <td className="result-text">
                      <p>Total: $ {productivityTotal !== null && productivityTotal !== undefined ? productivityTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="child-box shadow-left">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th className="table-head">Año</th>
                    <td className="table-head">Productividad en combustión</td>
                  </tr>
                </thead>
                <tbody>
                  {fuelProductivityTable === null ? (
                    <tr>
                      <td>No data available</td>
                    </tr>
                  ) : (
                    fuelProductivityTable.map((value, index) => {
                      const numberValue = parseFloat(value);
                      const formattedValue = value !== null && value !== undefined && !isNaN(numberValue)
                        ? numberValue.toLocaleString()
                        : value;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>$ {formattedValue}</td>
                        </tr>
                      );
                    })
                  )}

                  <tr>
                    <td className="result-text">
                      <p>Total: $ {fuelProductivityTotal !== null && fuelProductivityTotal !== undefined ? fuelProductivityTotal.toLocaleString() : ''}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>



        </div>
      )}



      <br /><br /><br />
    </div>
  );
};

export default TableTotals;
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import UpdateService from '../../../../services/admin/updates/updateService';
import LoaderUtilsService2 from '../../../../services/admin/utils/loaderUtilsService2';
import { FaBackward } from 'react-icons/fa';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';
import Spinner from '../../../../components/spinner/Spinner';

const UpdateCustom = ({ permission }) => {
    const params = useParams();
    const [datas, setDatas] = useState([]);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const url = `custom/${params.code}`;

    const getData = async () => {
        try {
            const custom_data = await LoaderUtilsService2(url);
            setDatas(custom_data[0])
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData()
    }, [setDatas, url]);

    useEffect(() => {
        if (Object.keys(datas).length > 0) {
            const customFormData = datas.custom && datas.custom[0]
                ? Object.keys(datas.custom[0]).reduce((acc, key) => {
                    acc[key] = datas.custom[0][key] || "";
                    return acc;
                }, {})
                : {};

            setFormData({
                code: datas.code || "",
                country_name: datas.country_name || "",
                flag_image: datas.flag_image || "",
                currency: datas.currency || "",
                ...customFormData,
            });
        }
    }, [datas]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedCustom = datas.custom && datas.custom[0]
            ? Object.keys(datas.custom[0]).reduce((acc, key) => {
                acc[key] = formData[key] || datas.custom[0][key];
                return acc;
            }, {})
            : {};

        const updatedDatas = {
            ...datas,
            code: formData.code,
            country_name: formData.country_name,
            flag_image: formData.flag_image,
            currency: formData.currency,
            custom: [updatedCustom]
        };

        console.log(updatedDatas)
        setShowForm(false);
        UpdateService('update_country', datas['id'], updatedDatas)
            .then(() => {
                alert('Datos actualizados con éxito');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
    };


    if (loading) {
        return <Spinner />;
    }

    if (!datas || datas.length <= 0 || datas.error || datas === null) {
        return <p>Sin país disponible</p>
    }

    return (
        permission ? (
            <>
                <div>
                    <Link to={`/admin/list_countries`}><FaBackward className="options-logo" />Volver a la lista</Link>
                    <br /><br />
                    <h3 className='test-var-color'>{datas.country_name} <span><img src={datas.flag_image} alt={`Bandera de ${datas.country_name}`} style={{ maxWidth: '35px' }} /></span></h3> 
                    <br /><br />
                    {showForm ? (
                        <>
                            <div className='container shadow-left p-3' style={{backgroundColor: 'white'}}>
                                <h4 className='subtitle mt-2'>Datos del país</h4>
                                <div className="row">
                                    <div className="col-3">
                                        <label className='modal-lavel'>
                                            Nombre
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="country_name"
                                                value={formData.country_name}
                                                onChange={handleChange}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <label className='modal-lavel'>
                                            Código
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="code"
                                                value={formData.code}
                                                onChange={handleChange}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <label className='modal-lavel'>
                                            Moneda
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="currency"
                                                value={formData.currency}
                                                onChange={handleChange}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <label className='modal-lavel'>
                                            Imagen de la bandera
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="flag_image"
                                                value={formData.flag_image}
                                                onChange={handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="row">
                                    <h4 className='subtitle mt-2'>Personalización</h4>
                                    {datas.custom && datas.custom[0] && Object.keys(datas.custom[0]).map((key) => (
                                        <div className="col-3" key={key}>
                                            <label className='modal-lavel'>
                                                {`${key.replace(/_/g, " ").toUpperCase()}`}
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    name={key}
                                                    value={formData[key] || ""}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <br />
                                <button onClick={handleSubmit}>Actualizar personalización</button>
                                <br />
                                <br />
                            </div>
                        </>
                    ) : (
                        <div className='container'>
                            <p>Personalización de {formData.country_name} actualizada <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                            <button onClick={handleOpenForm}>Personalizar</button>
                        </div>
                    )}
                    <br />
                    <div className='container'>
                        <Link to={`/admin/update_scraping/${formData.country_name}`}><button className='btn btn-success shadow-left'>Scrapings de {formData.country_name}</button></Link>
                    </div>
                </div>
            </>
        ) : (
            <>Sin permisos de Super admin</>
        )
    );
};

export default UpdateCustom;

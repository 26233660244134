import './styles/global.css'
import './styles/responsives.css'
import './styles/titles.css'
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/main/Main";
import Company from "./pages/company/Company";
import Mecanic from "./pages/mecanic/Mecanic";
import Features from "./pages/features/Features";
import BateryCharger from "./pages/bateryCharger/BateryCharger";
import ElectricSystem from "./pages/electricSystem/ElectricSystem";
import ElectricEngine from "./pages/electricEngine/ElectricEngine";
import Measures from "./pages/measures/Measures";
import SubSystem from "./pages/subSystem/SubSystem";
import Home from "./pages/home/Home";
import Axes from "./pages/axes/Axes";
import BodyWork from "./pages/bodyWork/BodyWork";
import Brakes from "./pages/brakes/Brakes";
import Direction from "./pages/direction/Direction";
import Environment from "./pages/environment/Environment";
import Financial from "./pages/financial/Financial";
import Homologation from "./pages/homologation/Homologation";
import Quoter from "./pages/quoter/Quoter";
import Speeds from "./pages/speeds/Speeds";
import Vehicles from "./pages/vehicles/Vehicles";
import Consumption from "./pages/consumption/Consumption";
import Maintenance from "./pages/maintenance/Maintenance";
import Batery from "./pages//batery/Batery";
import Assurance from "./pages/assurance/Assurance";
import Productivity from "./pages/productivity/Productivity";
import Totals from "./pages/totals/Totals";
import AllVehiclesAdmin from "./pages/admin/list/AllVehiclesAdmin";
import UpdateVehicle from "./pages/admin/updateVehicle/UpdateVehicle"
import MainAdmin from "./pages/admin/MainAdmin";
import New from "./pages/admin/new/New";
import DetailVehicle from "./pages/admin/detail/DetailVehicle";
import CardsTypes from "./pages/vehicles/components/CardsTypes"
import ListCategories from "./pages/admin/list/ListCategories"
import ListConsumables from "./pages/admin/list/ListConsumables"
import ListConsts from "./pages/admin/list/ListConsts"
import ListMaintenances from "./pages/admin/list/ListMaintenances"
import ListCountries from './pages/admin/list/ListCountries';
import DetailChart from './pages/admin/detail/DetailCharts';
import ListSoat from './pages/admin/list/ListSoat';
import ListBateryPrice from './pages/admin/list/ListBateryPrices';
import loaderSecure from './services/admin/secure/Secure';
import Login from './pages/login/Login';
import UpdateCustom from './pages/admin/updateVehicle/updates/UpdateCustom';
import CustomLoader from './scripts/CustomLoader.js';
import UpdateScraping from './pages/admin/updateVehicle/updates/UpdateScraping.js';
import UpdateSuperAdmin from './pages/admin/updateVehicle/updates/UpdateSuperAdmin.js';


function App() {

  const [authenticated, setAuthenticated] = useState(false);
  const [admin, setAdmin] = useState(false)
  const [superAdmin, setSuperAdmin] = useState(false)

  const handleSecure = async () => {
    try {
      const sec = await loaderSecure()
      if (sec === null) {
        // localStorage.setItem("region", `admin`);
        return
      }
      

      for (let index = 0; index < sec.data.length; index++) {
        
        if (sec.data[index].permiso_id === '63') {
          localStorage.setItem("region", sec['pais']);
          setAuthenticated(true)
        }
        if (sec.data[index].permiso_id === '59') {
          setAdmin(true)
        }
        if (sec.data[index].permiso_id === '71') {
          localStorage.setItem("region", `admin`);
          setSuperAdmin(true)
        }
      }

    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    handleSecure()
    CustomLoader()
  }, [])

  if (authenticated === null) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      {authenticated ? (
        <BrowserRouter>
          <div className="main-screen">
            <Routes>
              <Route path='/' element={<Main />} />

              {admin ? (
                <Route path="admin/" element={<MainAdmin superAdmin={superAdmin} />}>
                  <Route path="charts" element={<DetailChart />} />
                  <Route path="list" element={<AllVehiclesAdmin />} />
                  <Route path="new" element={<New />} />
                  <Route path="detail/:id" element={<DetailVehicle />} />
                  <Route path="update/:category/:fuel/:id" element={<UpdateVehicle />} />
                  <Route path="list_consumables" element={<ListConsumables />} />
                  <Route path="maintenances/:id" element={<ListMaintenances />} />
                  
                  {/*  Superadmin routes  */}
                  <Route path="list_categories" element={<ListCategories permission={superAdmin}/>} />
                  <Route path="list_soat" element={<ListSoat permission={superAdmin}/>} />
                  <Route path="list_batery" element={<ListBateryPrice permission={superAdmin}/>} />
                  <Route path="consts" element={<ListConsts permission={superAdmin}/>} />
                  <Route path="list_countries" element={<ListCountries permission={superAdmin}/>} />
                  <Route path="update_custom/:code" element={<UpdateCustom permission={superAdmin}/>} />
                  <Route path="update_Super_admin/:code" element={<UpdateSuperAdmin permission={superAdmin}/>} />
                  <Route path="update_scraping/:code" element={<UpdateScraping permission={superAdmin}/>} />
                </Route>
              ) : (
                <Route path='admin/*' element={<Login text={'Sin permisos de administrador, inicia sesión para continuar'} />} />
              )}
              <Route path="types/:type" element={<CardsTypes />} />
              <Route path='/categories' element={<Vehicles admin={admin} superAdmin={superAdmin} />} />

              <Route path="home/:id/:type/" element={<Home />}>
                <Route
                  path="quoter"
                  element={
                    <Quoter />
                  }
                />
                <Route
                  path="environment"
                  element={
                    <Environment />
                  }
                />
                <Route
                  path="financial"
                  element={
                    <Financial />
                  }
                />

                <Route
                  path="consumption"
                  element={
                    <Consumption />
                  }
                />
                <Route
                  path="maintenance"
                  element={
                    <Maintenance />
                  }
                />
                <Route
                  path="batery"
                  element={
                    <Batery />
                  }
                />
                <Route
                  path="assurance"
                  element={
                    <Assurance />
                  }
                />
                <Route
                  path="productivity"
                  element={
                    <Productivity />
                  }
                />
                <Route
                  path="total"
                  element={
                    <Totals />
                  }
                />

                <Route path="subsystems/*" element={<SubSystem />}>
                  <Route
                    path="mecanic/:id/"
                    element={
                      <Mecanic />
                    }
                  >
                    <Route
                      path="company/:id"
                      element={
                        <Company />
                      }
                    />
                    <Route
                      path="homologation/:id"
                      element={
                        <Homologation />
                      }
                    />
                    <Route
                      path="bodywork/:id"
                      element={
                        <BodyWork />
                      }
                    />
                    <Route
                      path="axes/:id"
                      element={

                        <Axes />

                      }
                    />
                    <Route
                      path="speeds/:id"
                      element={

                        <Speeds />

                      }
                    />
                    <Route
                      path="direction/:id"
                      element={

                        <Direction />
                      }
                    />
                    <Route
                      path="brakes/:id"
                      element={
                        <Brakes />
                      }
                    />
                    <Route
                      path="measures/:id"
                      element={
                        <Measures />
                      }
                    />
                  </Route>
                  <Route
                    path="features/:id"
                    element={
                      <Features />
                    }
                  />

                  <Route
                    path="electric_system/:id"
                    element={
                      <ElectricSystem />
                    }
                  />
                  <Route
                    path="electric_engine/:id"
                    element={
                      <ElectricEngine />
                    }
                  />
                  <Route
                    path="batery_charger/:id"
                    element={
                      <BateryCharger />
                    }
                  />
                </Route>
              </Route>
              <Route path="*" element={<h1>404 Page Not Found :d</h1>} />
            </Routes>
          </div>
        </BrowserRouter >
      ) : (
        /* Enlace para redirigir al usuario a la página de inicio de sesión */
        <div>
          <Login text={'Debes iniciar sesión para continuar'} />
        </div>
      )}
    </div>
  );
}

export default App;
